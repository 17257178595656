import * as React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import SEO from "components/SEO"
import { FooterConfig } from "components/Footer"
import ModuleRender from "components/ModuleRender"
import { HeaderConfig } from "components/Header"
import { HeaderVariant } from "components/Header/types"

export default function BlogPost({ data }) {
	const page = data.contentfulPage
	// use slug instead of page seo
	const isBrandInsightsClue = page.seo && page.seo.title ==="Brand Performance Insights: Clue"
	const isBrandInsightsWish = page.seo && page.seo.title ==="Brand Performance Insights: Wish"

	if (!page) {
		return null
	}
	const { sections, seo, hideFooterDemo, transparentHeader, headerVariant, secondaryImage, footerVariant } = page

	const footerConfig: FooterConfig = React.useMemo(() => {
		return {
			hideDemo: Boolean(hideFooterDemo),
			hideNewsletter: false,
			variant: footerVariant
		}
	}, [hideFooterDemo, footerVariant])

	const headerConfig: HeaderConfig = React.useMemo(() => {
		return {
			variant: transparentHeader ? HeaderVariant.TransparentWhite : headerVariant || HeaderVariant.Default,
		}
	}, [transparentHeader, headerVariant])

	return (
		<>
			<SEO {...seo} />
			<Layout footerConfig={footerConfig} headerConfig={headerConfig}>
				<ModuleRender sections={sections} secondaryImage={secondaryImage} />
			</Layout>
			{isBrandInsightsClue && <img height="1" width="1" style={{display:"none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=1088361&conversionId=5692265&fmt=gif" />}
			{isBrandInsightsWish && <img height="1" width="1" style={{display:"none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=1088361&conversionId=5876537&fmt=gif" />}
		</>
	)
}

export const query = graphql`
	query ($slug: String) {
		contentfulPage(slug: { eq: $slug }) {
			hideFooterDemo
			transparentHeader
			headerVariant
			footerVariant
			seo {
				...SEO
			}
			sections {
				... on ContentfulAlternatingCards {
					...AlternatingCards
				}
				... on ContentfulBrandImagesSection {
					...BrandImages
				}
				... on ContentfulBrandImagesWithReviews {
					...BrandImagesWithReviews
				}
				... on ContentfulLandingSections {
					...Section
				}
				... on ContentfulReferencesSection {
					...ReferencesSection
				}
				... on ContentfulEmbedSpotifySection {
					...EmbedSpotifySection
				}
				... on ContentfulTimeline {
					...Timeline
				}
				... on ContentfulForm{
					...Form
				}
                ... on ContentfulBrandCategorySection {
                    ...BrandCategorySection
                }        
			}
			secondaryImage {
				...MediaOriginal
			}
		}
	}
`
